<template>
  <div>
    <a-row>
      <a-col :lg="12"
             :md="12"
             :sm="24">
        <a-form-item label="当前项目"
                     class="default-highlight"
                     :label-col="{ span: 4 }"
                     :wrapper-col="{ span: 12 }"
                     style="margin-bottom:20px;">
          <a-input :disabled="true"
                   :placeholder="msg"
                   class="inputcolor"></a-input>
        </a-form-item>
      </a-col>

      <a-col :lg="24"
             :md="24"
             :sm="24"
             v-if="record.length">
        <div class="comment-container"
             v-for="(item, index) in record"
             :key="index">
          <div>
            <div class="index">{{index+1}}</div>
            <div class="comment">
              <a-form-item label="审核意见"
                           :label-col="{ span: 2 }"
                           :wrapper-col="{ span: 21 }">
                <a-input v-model="item.review"
                         class="inputcolor"></a-input>
              </a-form-item>
              <a-form-item label="处理意见"
                           :label-col="{ span: 2 }"
                           :wrapper-col="{ span: 21 }">
                <a-input v-model="item.solution"
                         class="inputcolor"></a-input>
              </a-form-item>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :lg="24"
             :md="24"
             :sm="24"
             :offset="11"
             v-else>
        无审核记录
      </a-col>

    </a-row>
    <div class="center">
      <a-space>
        <a-button @click="$close($route.path)">关闭</a-button>
      </a-space>
    </div>

  </div>
</template>
<script>
import { fetchDetail } from "@/api/epc/construction";

export default {
  props: {
    msg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      id: "",
      record: [],
    };
  },
  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    this.id = id;

    if (this.id) {
      this.getList();
    }
  },
  methods: {
    getList() {
      fetchDetail({ id: this.id }).then((res) => {
        this.record = res.commentList;
        console.log(this.record);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.inputcolor {
  color: #1890ff;
}
.comment-container {
  padding: 10px 30px;
  margin-bottom: 10px;
  > div {
    margin: 0px 10px;
    padding: 10px 10px 10px 30px;
    border: 1px solid #ccc;
    border-radius: 10px;
    position: relative;
  }
  .index {
    padding: 0px 10px;
    border: 1px solid #1890ff;
    font-weight: 800;
    font-size: 1.5em;
    color: #1890ff;
    position: absolute;
    top: 13px;
  }
  .comment {
    margin-left: 50px;
  }
  .ant-form-item:last-child {
    margin-bottom: 0px;
  }
}

.center {
  margin-top: 80px;
}
</style>
