<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane key="0"
                    tab="施工组织设计"> </a-tab-pane>
        <a-tab-pane key="1"
                    tab="施工组织设计所有审批意见"> </a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <ExportDownload :isView="isView"
                        :url="imgUrl"
                        width="50%"
                        @exportForm="exportForm"
                        @download="download" />
      </template>
    </Pane>

    <div class="container">
      <ConstructionOrganise ref="form"
                            v-show="activeKey === '0' && pData.projectName"
                            :msg="pData"></ConstructionOrganise>
      <ReviewRecords v-show="activeKey === '1' && pData.projectName"
                     :msg="pData.designCode"></ReviewRecords>
    </div>
  </div>
</template>

<script>
import ExportDownload from "@/views/epc/components/ExportDownload";
import ConstructionOrganise from "./components/ConstructionOrganise";
import ReviewRecords from "./components/ReviewRecords";

import { fetchDetail } from "@/api/epc";
import { mapGetters } from "vuex";
export default {
  name: "construOrg",
  components: {
    ExportDownload,
    ConstructionOrganise,
    ReviewRecords,
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  data() {
    return {
      imgUrl: require("@/assets/epc/approvalFlow/4-1.png"),
      activeKey: "0",

      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      pData: {},

      fileList: [],
      selectedFileList: [],
    };
  },

  mounted() {
    const { query } = this.$route;
    const { pid, isView } = query || {};

    this.isView = Boolean(isView) || false;

    if (!pid) {
      return;
    }
    this.pid = pid;
    this.getProjectInfo();
  },

  methods: {
    getProjectInfo() {
      fetchDetail(this.pid).then((res) => {
        this.pData = {
          pid: this.pid,
          designCode: res.designCode,
          projectName: res.name,
        };
      });
    },

    download() {
      this.$refs.form.download();
    },
    exportForm() {
      this.$refs.form.exportForm();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}
</style>
